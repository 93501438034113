<template>
  <div
    class="d-flex flex-column"
    style="height:100%;margin-top:0px;"
  >
    <!-- carousel -->
    <div
      v-show="show"
      class="d-flex d-sm-none align-items-center justify-content-center flex-grow-1"
    >
      <Swiper
        id="swiper"
        class="swiper"
        :options="swiperOptions"
        @slideChange="slideChange"
      >
        <SwiperSlide
          v-for="(option) in options"
          :key="option"
          style=";position: relative;"
          class="cardHeightAdjust border d-flex align-items-center justify-content-center cp"
          :class="{'cardHeightAdjust': safari === false, 'cardHeightAdjustSafari': safari }"
          @mouseover="hoveringOver = option"
          @mouseleave="hoveringOver = -1"
        >
          <img
            :src="(hoveringOver === option || option === hero)
              ? require(`../../assets/charSelect/${option}.colour.png`)
              : require(`../../assets/charSelect/${option}.greyed_out.png`)"
            class="mobileHeroImage"
            @click="selectHero(option)"
          >
        </SwiperSlide>
      </Swiper>
    </div>

    <!-- grid -->
    <div class="grid-8-swiper p-3">
      <div
        v-for="(option) in options"
        :key="option"
        class="gridItem"
        style="height:99%;"
        @click="selectHero(option)"
        @mouseover="hoveringOver = option"
        @mouseleave="hoveringOver = -1"
      >
        <CheckedCircle v-if="option === hero" />
        <img
          :src="(hoveringOver === option || option === hero)
            ? require(`../../assets/charSelect/${option}.colour.png`)
            : require(`../../assets/charSelect/${option}.greyed_out.png`)"
          style="width:100%;max-height:100%;height:100%;object-fit:cover;object-position:55% 50%"
        >
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import CheckedCircle from '@/components/checkedCircle.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'Hero',
  components: {
    SwiperSlide,
    CheckedCircle,
    Swiper
  },
  data () {
    return {
      swiper: null,
      show: true,
      hoveringOver: -1,
      options: [1, 2, 3, 4, 5, 6, 7, 8],
      swiperOptions: {
        effect: 'coverflow',
        slidesPerView: 2,
        slidesPerGroup: 1,
        centeredSlides: true,
        loop: true,
        spaceBetween: 30,
        coverflowEffect: {
          rotate: -40,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true
        }
      }
    }
  },
  computed: {
    ...mapGetters(['hero', 'safari'])
  },
  watch: { 
    '$route.path': {
      handler: function(path) {
        if (path === '/play') {
          this.show = false
          this.$nextTick(() => {
            this.show = true
          })
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    this.swiper = document.getElementsByClassName('swiper')[0].swiper
    setTimeout(() => {
      if (this.hero != 0) {
        this.swiper.slideTo(this.hero + 1, 1000, true)
      } else {
        this.swiper.slideTo(2, 1000, true)
      }
    }, 1000)
  },
  methods: {
    slideChange () {
      this.selectHero(this.swiper.realIndex + 1)
    },
    selectHero (option) {
      this.$store.commit('storeHero', option)
    }
  }
}
</script>

<style>
@media (max-width: 576px) {
  .cardHeightAdjust {
    height: calc(100dvh - 390px) !important;
    margin-bottom: 20px;
  }
  .cardHeightAdjustSafari {
    height: calc(100vh - 420px) !important;
    margin-bottom: 20px;
  }
}
.mobileHeroImage {
  object-fit:contain;
  object-position:55% 50%;
  width:100%;
  height:100% !important;
  background: rgb(28, 28, 28);
}
</style>